import toast from 'react-hot-toast';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  status,
  message,
  autoClose,
}: {
  status?: "success" | "error" | "info" | "warning";
  message: string;
  autoClose?: number;
}) => {
  if (status === "error") {
    toast.error(message, {
      position: "top-center",
      duration: autoClose || 5000,
    });
  }
  else {
    toast.success(message, {
      position: "top-center",
      duration: autoClose || 5000,
    });
  }
}
