import axios from "axios";
// @ts-ignore
import notification from "components/common/notification";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUserProfile } from "redux/auth/authSlice";
import {
  useGetUserByEmailMutation,
  useResendActivationEmailMutation,
} from "services/baseApiSlice";

export function useHandleAuth() {
  const { data: session } = useSession();

  const router = useRouter();

  const dispatch = useDispatch();

  const [getUser] = useGetUserByEmailMutation();

  const getUserByEmail = async (email: string) => {
    if (!email) return;
    await getUser({ email })
      .unwrap()
      .then((payload: any) => {
        dispatch(setUserProfile(payload?.user));
        dispatch(setToken(payload?.token));
      })
      .catch((error: any) => {
        notification({
          status: "error",
          message: error.data.message,
        });
      });
  };

  const [isLoading, setIsLoading] = useState(false);

  const userProfile = useSelector((state: any) => state.auth.userProfile);

  const userExists = async () => {
    setIsLoading(true);
    try {
      const resEmail = await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/users/checkemail`,
        {
          email: session?.user?.email,
        }
      );
      if (resEmail.data.message === "Email already exists" && !userProfile) {
        getUserByEmail(session?.user?.email as string);
      } else {
        if (router.pathname === "/") return;
        if (userProfile) return;
        const names = session?.user?.name?.split(" ");
        const res = await axios.post(
          `${process.env.NEXT_PUBLIC_API_URL}/users`,
          {
            firstName: names ? names[0] : session?.user?.email,
            lastName: names ? names[1] : session?.user?.email,
            email: session?.user?.email,
            authType: "google",
          }
        );
        dispatch(setToken(res?.data?.user?.token));
        dispatch(setUserProfile(res.data.user));
        setIsLoading(false);
      }
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
      if (error?.response?.message === "Email already exists") {
        getUserByEmail(session?.user?.email as string);
      }
      // notification({
      //   status: "error",
      //   message: error?.response?.data?.message,
      // });
    }
  };

  return { isLoading, userExists };
}

export function VerifyBanner() {
  const userProfile = useSelector((state: any) => state.auth.userProfile);
  const userId = userProfile?._id;
  const router = useRouter();
  const { tzwOrigin } = router.query;
  const [resendLink, { isLoading, isSuccess }] =
    useResendActivationEmailMutation();

  const resendHandler = async () => {
    const body = {
      userId,
      tzwOrigin,
    };
    await resendLink({ body })
      .unwrap()
      .then((payload: any) => {
        notification({
          status: "success",
          message: payload.message,
        });
      })
      .catch((error) => {
        notification({
          status: "error",
          message: error.data.message,
        });
      });
  };
  return (
    <div className="z-[150] flex h-[100px] top-0 min-h-[50px] w-full flex-col items-center justify-center gap-2 bg-[#FEFED2] px-2 text-center font-semibold text-[#104553] fixed md:top-0 md:h-[60px] md:flex-row md:justify-between md:gap-6 md:px-[5%]">
      <div className="flex items-center gap-6 md:justify-start md:text-left">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="h-8 w-8"
        >
          <path
            fillRule="evenodd"
            d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
            clipRule="evenodd"
          />
        </svg>

        <p className="text-xs md:text-sm">
          Please check your email and follow the link to verify your email
          address
        </p>
      </div>

      <button
        onClick={() => resendHandler()}
        disabled={userProfile?.isActive || isLoading || isSuccess}
        className="bg-[#50525F] px-6 py-1 text-[#fff]"
      >
        {isLoading
          ? "Loading..."
          : `${isSuccess ? "Sent" : "Resend Verification Email"}`}
      </button>
    </div>
  );
}
