/* eslint-disable no-console */
import axios from "axios";
// @ts-ignore
import notification from "components/common/notification";
// @ts-ignore
import { Spinner } from "components/common/spinner";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { signIn, signOut, useSession } from "next-auth/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setBusinessProfile,
  setToken,
  setUserProfile,
} from "redux/auth/authSlice";
import {
  useGetShopInfoPostMutation,
  useGetUserByEmailMutation,
} from "services/baseApiSlice";

import { useHandleAuth } from "./useAuth";
import { useLogout } from "./useLogout";

export default function AuthModal() {
  const router = useRouter();
  const dispatch = useDispatch();
  const { data: session } = useSession();
  const userProfile = useSelector((state: any) => state.auth.userProfile);
  const businessProfile = useSelector(
    (state: any) => state.auth.businessProfile
  );

  const [seenToast, setSeenToast] = useState(false);

  const [getUser, { isLoading: loading }] = useGetUserByEmailMutation();

  const [noStore, setNoStore] = useState(false);

  const getUserByEmail = async (email: string, isGoogle = false) => {
    if (!email || noStore) return;
    await getUser({ email })
      .unwrap()
      .then((payload: any) => {
        if (payload?.token) {
          dispatch(setToken(payload?.token));
        }
        dispatch(setUserProfile(payload?.user));

        if (!isGoogle) {
          if (payload?.user?.stores?.length > 0 && !session) {
            if (token && user) {
              router.push(
                `/${payload?.user?.stores[0]?.storeId}?token=${token}&user=${user}`
              );
            } else {
              router.push(`/${payload?.user?.stores[0]?.storeId}`);
            }
          } else {
            notification({
              status: "error",
              message: "You don't have any store yet.",
            });
            setNoStore(true);
            signOut({ callbackUrl: "/", redirect: false });
            dispatch(setUserProfile(undefined));
          }
        } else {
          getShopHandler(
            payload?.user?.stores[0]?.storeId,
            true,
            payload?.user,
            true
          );
        }
      })
      .catch((error) => {
        if (!session) {
          signOut({ callbackUrl: "/", redirect: false });
          dispatch(setUserProfile(undefined));
          if (!isGoogle) {
            notification({
              status: "error",
              message:
                error?.data?.message ??
                "Account not found. Please create an account.",
            });
          }
        } else {
          // check ig status is 404
          if (error?.status === 404) {
            notification({
              status: "error",
              message:
                "No account is associated with that email. Please create an account.",
            });
            signOut({ callbackUrl: "/onboarding", redirect: false });
          }
        }
      });
  };

  const [getShop, { isLoading }] = useGetShopInfoPostMutation();

  const getShopHandler = async (
    storeId: string,
    afterSign: boolean,
    obj: any,
    skipEmailFetch = false
  ) => {
    if (businessProfile?._id || seenToast) return;
    await getShop({ storeId })
      .unwrap()
      .then((payload: any) => {
        if (payload?.verificationStatus === "verified") {
          dispatch(setBusinessProfile(payload));
          setSeenToast(true),
            !skipEmailFetch
              ? getUserByEmail(obj.email, false)
              : token && user
              ? router.push(`/${payload?._id}?token=${token}&user=${user}`)
              : router.push(`/${payload?._id}`);
        } else if (payload?.verificationStatus === "declined") {
          notification({
            status: "error",
            message: "Your store was declined.",
          });
          signOut({ callbackUrl: "/", redirect: false });
          dispatch(setUserProfile(undefined));
        } else {
          notification({
            status: "error",
            message: "Your store is not yet verified.",
          });
          if (session) return;
          signOut({ callbackUrl: "/", redirect: false });
          dispatch(setUserProfile(undefined));
        }
      })
      .catch((error) => {
        if (session) return;
        signOut({ callbackUrl: "/", redirect: false });
        dispatch(setUserProfile(undefined));
        notification({
          status: "error",
          message:
            error?.data?.message ??
            "Something went wrong. Please try again later.",
        });
      });
  };

  const [fetching, setFetching] = useState(false);

  const { userExists } = useHandleAuth();

  useEffect(() => {
    if (!userProfile && session) {
      userExists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  // login email
  const loginUser = async (
    obj: any,
    afterSign = false,
    google = false,
    userProfile: any = null
  ) => {
    try {
      if (!google) {
        if (!obj.email || !obj.password) {
          notification({
            status: "error",
            message: "Email and password are required.",
          });
          return;
        }
        setFetching(true);
        const res = await axios.post(
          `${process.env.NEXT_PUBLIC_API_URL}/users/auth`,
          {
            email: obj.email,
            password: obj.password,
          }
        );
        if (res?.data?.user?.stores?.length === 0) {
          notification({
            status: "error",
            message: "Store not found. Please create a store.",
          });
          setFetching(false);
          return;
        }
        dispatch(setToken(res?.data?.accessToken));
        dispatch(setUserProfile(res?.data?.user));
        setFetching(false);
        // later on, a user may have multiple stores. Selected store ID will be saved on app store. For now we just get the first store
        getShopHandler(res?.data?.user?.stores[0]?.storeId, false, obj, true);
      } else {
        getUserByEmail(session?.user?.email as string, true);
        // later on, a user may have multiple stores. Selected store ID will be saved on app store. For now we just get the first store
        if (userProfile?.stores?.length === 0) {
          notification({
            status: "error",
            message: "Store not found. Please create a store.",
          });
          return;
        }
        if (userProfile?.stores?.length > 0) {
          getShopHandler(userProfile?.stores[0]?.storeId, true, obj);
        }
        // later on, a user may have multiple stores. Selected store ID will be saved on app store. For now we just get the first store
      }
    } catch (error: any) {
      setFetching(false);
      if (session) return;
      notification({
        status: "error",
        message:
          error?.response?.data?.message ??
          "Something went wrong. Please try again.",
      });
      signOut({ callbackUrl: "/", redirect: false });
      dispatch(setUserProfile(undefined));
    }
  };

  const { token, user } = router.query;

  useEffect(() => {
    // @ts-ignore
    if (session && !userProfile) {
      loginUser(null, true, true, session?.user?.email as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, userProfile]);

  const { logout } = useLogout();

  useEffect(() => {
    if (
      userProfile &&
      userProfile?._id &&
      userProfile?.stores?.length > 0 &&
      !businessProfile?._id
    ) {
      if (userProfile?.stores?.length > 0 && !seenToast) {
        getShopHandler(
          userProfile?.stores[0]?.storeId,
          true,
          {
            email: userProfile?.email,
          },
          true
        );
      }
    }
  }, [userProfile, businessProfile, session, seenToast]);

  useEffect(() => {
    if (
      userProfile &&
      userProfile?._id &&
      userProfile?.stores?.length > 0 &&
      businessProfile &&
      businessProfile?._id
    ) {
      if (token && user) {
        router.push(
          `/${userProfile?.stores[0]?.storeId}?token=${token}&user=${user}`
        );
      } else {
        // later on, a user may have multiple stores. Selected store ID will be saved on app store. For now we just get the first store
        router.push(`/${userProfile?.stores[0]?.storeId}`);
      }
    }
    // if we have a business profile and no user profile _id field, we logout
    if (businessProfile && !userProfile?._id) {
      logout();
    }
  }, [userProfile, businessProfile, token, user, router]);


  if (userProfile || session)
    return (
      <div className="fixed inset-0 z-50 flex h-full w-full items-center justify-center">
        <Spinner />
      </div>
    );

  return (
    <div className="fixed inset-0 z-[120] flex h-full w-full items-center justify-center  bg-white-50">
      <div className="relative h-screen w-screen overflow-y-auto rounded-none bg-white-50 p-[40px] px-[20px] py-[60px] pb-[70px] shadow-md md:h-max md:max-h-[700px] md:w-[400px] md:rounded-[8px]">
        <h1
          className="w-full
        text-center text-2xl font-semibold text-[#404145]"
        >
          Login to Continue
        </h1>

        <div className="mx-auto mt-8 flex w-[90%] flex-col gap-2">
          <OneProvider
            icon="/images/svgs/google.png"
            text="Continue with Google"
            action={() => signIn("google", { callbackUrl: "/" })}
          />
        </div>
        <div className="relative mx-auto mt-4 flex h-[40px] w-[90%] items-center">
          <div className="hr-color h-[1px] w-full"></div>
          <span className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 bg-white-50 px-4 text-[#62646a]">
            OR
          </span>
        </div>

        <LoginForm
          loading={isLoading || loading || fetching}
          loginUser={(obj) => loginUser(obj)}
        />

        <div className="absolute bottom-[6rem] mx-auto flex w-[90%] flex-col text-center md:bottom-4">
          <p className="text-sm text-[#62646a]">
            Not a member yet?{" "}
            <Link
              className="cursor-pointer font-medium text-[#1dbf73] hover:underline"
              href="/onboarding"
            >
              Create account
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

function OneProvider({
  icon,
  text,
  action,
}: {
  icon: string;
  text: string;
  action: () => void;
}) {
  return (
    <div
      onClick={() => action()}
      className="relative mx-auto flex w-full cursor-pointer items-center justify-center gap-4 rounded-[4px] border-[1px] border-[#e4e5e7] bg-white-50 px-8 py-3 pl-2"
    >
      <Image
        src={icon}
        height={20}
        width={20}
        className="absolute left-4"
        alt={text}
      />
      <span className="text-[16px] font-semibold text-[#62646a]">{text}</span>
    </div>
  );
}

function LoginForm({
  loginUser,
  loading,
}: {
  loginUser: (obj: any) => Promise<void>;
  loading: boolean;
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);

  const validateEmail = (email: string) => {
    const re = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    return re.test(email);
  };

  const [showPassword, setShowPassword] = useState(false);

  return (
    <form className="mx-auto mt-4 flex w-[90%] flex-col gap-4">
      <div className="flex flex-col gap-1">
        <input
          type="email"
          name="email"
          id="email"
          required
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailIsInvalid(validateEmail(e.target.value));
          }}
          className="common-input h-[45px] placeholder:text-[#62646a]/80"
          placeholder="Email"
        />
        {!emailIsInvalid && email.length > 0 && (
          <p className="text-xs text-red">Email is invalid</p>
        )}
      </div>
      <div className="relative flex flex-col gap-1">
        <input
          required
          type={showPassword ? "text" : "password"}
          id="password"
          name="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          placeholder="Password"
          className="common-input h-[45px] placeholder:text-[#62646a]/80"
        />
        {showPassword ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="absolute top-2 right-2 cursor-pointer"
            onClick={() => setShowPassword(false)}
          >
            <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
            <path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
            <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
            <line x1="2" y1="2" x2="22" y2="22"></line>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="absolute top-2 right-2 cursor-pointer"
            onClick={() => setShowPassword(true)}
          >
            <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
            <circle cx="12" cy="12" r="3"></circle>
          </svg>
        )}
      </div>
      <div className="flex items-center justify-between text-sm font-medium text-[#1dbf73]">
        {/* remember me */}
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            className="h-[15px] w-[15px] checked:border-[#1dbf73] checked:bg-[#1dbf73]"
            id="remember"
            name="remember"
          />
          <label htmlFor="remember" className="text-gray-200">
            Remember Me
          </label>
        </div>
        <Link href="/password-reset" className="">
          Forgot Password
        </Link>
      </div>
      <button
        onClick={(e) => {
          e.preventDefault();
          loginUser({
            email,
            password,
          });
        }}
        disabled={email.length === 0 || password.length === 0 || loading}
        className="w-full rounded-md bg-[#1dbf73] py-2 text-lg font-semibold text-white-50 transition-all duration-300 ease-in hover:bg-[#19a463] disabled:opacity-50"
      >
        {loading ? "Loading..." : "Continue"}
      </button>
    </form>
  );
}
