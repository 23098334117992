import { signOut } from "next-auth/react";
import { useDispatch } from "react-redux";
import { resetAuth } from "redux/auth/authSlice";
import { resetShowState } from "redux/show/showSlice";

export const useLogout = () => {
  const dispatch = useDispatch();
  const logout = () => {
    signOut({ redirect: true});
    localStorage.clear();
    dispatch(resetAuth());
    dispatch(resetShowState());
    // window.location.href = "/";
  };
  return { logout };
};
